import React, { useState } from 'react'
import Header from './Layout/header'
import Footer from './Layout/footer'
// import WELCOME from "../assets/img/aboutimg.png"
// import BENEFIT from "../assets/img/benefit-1.png"
// import BENEFIT2 from "../assets/img/benefit-2.png"
// import BENEFIT3 from "../assets/img/benefit-3.png"
// import BENEFIT4 from "../assets/img/benefit-4.png"

const About = () => {
    const [check, setCheck] = useState({
        1: true,
        2: true,
        3: true,
        4: true,
        5: true
    });
    const handleCheck = id => {
        let f_data = {}
        const chk_data = Object.keys(check);
        for (let index = 0; index < chk_data.length; index++) {
            const element = chk_data[index];
            console.log(element, id, 'chk dataa')
            if (id == element) {
                f_data[element] = !check[element]
            } else {
                f_data[element] = true
            }
            setCheck(f_data)
        }
    };
    return (
        <div>
            <Header />
            <div>

                {/* <!-- HERO SECTION PART START --> */}
                <div class="hero_section">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="herosection_content">
                                    <h2>About</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- HERO SECTION PART END --> */}

                <section class="about">
                    <div class="container">
                        <div class="row align">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="about-content">
                                    <h3><b>Welcome to Harvest Fresh -</b><span style={{ color: "#60cd12" }}> Where Freshness Meets Your Doorstep!</span></h3><br />
                                    <p><b>At Harvest Fresh, we have sown the seeds of a vibrant foundation aimed at
                                        bringing the farm-to-table experience right to your home.</b></p><br />
                                    <p>Our commitment revolves around cultivating the freshest vegetables and fruits, ensuring they
                                        journey directly from our farms to your household once a week. What sets us
                                        apart is our promise to deliver produce that has been harvested within a mere
                                        48 hours before reaching your doorstep, ensuring you receive the pinnacle of
                                        freshness.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="about-image">
                                    {/* <img src={WELCOME} alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </section>

                <section class="benefits">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    {/* <img src={BENEFIT} alt='' /> */}
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#004318" }}>
                                    <div class="card-body">
                                        <h3><b>The Benefits of Eating Fresh Vegetables:</b></h3>
                                        <p>Embark on a journey of taste and health with our freshly harvested vegetables
                                            and fruits. Our produce is packed with essential nutrients and flavors, offering
                                            you a delectable palette of nature&#39;s best. Consuming fresh vegetables has
                                            myriad benefits, from heightened nutritional content to enhanced taste.
                                            Harvest Fresh is your gateway to a healthier lifestyle, where every bite is a
                                            celebration of freshness and well-being.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mobile-reverse">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#008849" }}>
                                    <div class="card-body">
                                        <h3><b>The Advantages of Buying From Us Online:</b></h3>
                                        <p>Convenience meets quality when you choose Harvest Fresh online. With just a
                                            few clicks, you can explore our wide array of freshly harvested produce and
                                            have them delivered directly to your doorstep. Say goodbye to the hassle of
                                            traditional grocery shopping and revel in the ease of online ordering. We
                                            prioritize your time, ensuring that you have more of it to savor the goodness of
                                            our farm-fresh offerings.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    {/* <img src={BENEFIT2} alt='' /> */}
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    {/* <img src={BENEFIT3} alt='' /> */}
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#12a05ed0" }}>
                                    <div class="card-body">
                                        <h3><b>Benefits for Farmers:</b></h3>
                                        <p>Harvest Fresh is not just about providing fresh produce to our customers; it&#39;s
                                            about cultivating a community that benefits everyone involved. By choosing
                                            Harvest Fresh, you&#39;re supporting local farmers who are an integral part of our
                                            journey. We believe in fair compensation for their hard work and dedication,
                                            fostering a sustainable relationship that helps farmers thrive.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mobile-reverse">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="card" style={{ backgroundColor: "#66a87d" }}>
                                    <div class="card-body">
                                        <h3><b>More Eco-Friendly Farming:</b></h3>
                                        <p>Our commitment extends beyond freshness; it embraces a responsibility
                                            towards the environment. Harvest Fresh promotes eco-friendly farming
                                            practices that prioritize sustainability. By choosing us, you contribute to a
                                            greener future. From minimizing waste to promoting biodiversity, our farming
                                            methods are designed to leave a positive impact on the planet.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="benefit-image">
                                    {/* <img src={BENEFIT4} alt='' /> */}
                                </div>
                            </div>
                        </div>


                    </div>
                </section>

                <section class="mission">
                    <div class="container">
                        <div class="row align">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="mission-left">
                                    <h2>Our Mission</h2>
                                    <p>At Harvest Fresh, our mission is to cultivate a healthier and more sustainable world through
                                        the delivery of fresh, farm-to-table produce. We are committed to redefining the way
                                        people experience and consume fruits and vegetables, fostering a connection between the
                                        fields where they grow and the households they nourish.</p>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="mission-right">
                                    <ul>
                                        <li>
                                            <input type="checkbox" checked={check['1']} onChange={() => handleCheck(1)} />
                                            <i></i>
                                            <h2>Freshness Beyond Compare</h2>
                                            <p>We strive to redefine the standard of freshness by ensuring that every piece of produce we
                                                deliver is cultivated with care and delivered to your doorstep within 48 hours of harvesting.
                                                Our commitment to unparalleled freshness is at the core of everything we do.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['2']} onChange={() => handleCheck(2)} />
                                            <i></i>
                                            <h2>Healthier Lives, One Bite at a Time</h2>
                                            <p>We believe that access to fresh, nutrient-rich fruits and vegetables is fundamental to a
                                                healthy lifestyle. Our mission is to make these essential elements easily accessible to
                                                everyone, promoting well-being and contributing to healthier communities.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['3']} onChange={() => handleCheck(3)} />
                                            <i></i>
                                            <h2>Empowering Local Farmers</h2>
                                            <p>Harvest Fresh is not just a marketplace; it&#39;s a platform that empowers local farmers. Our
                                                mission includes providing fair compensation, fostering sustainable farming practices, and
                                                creating opportunities for farmers to thrive. By choosing Harvest Fresh, you support a
                                                network of dedicated farmers who share our commitment to quality and sustainability.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['4']} onChange={() => handleCheck(4)} />
                                            <i></i>
                                            <h2>Convenience without Compromise</h2>
                                            <p>We aim to make fresh and healthy choices easy for you. Our online platform offers a
                                                convenient and seamless shopping experience, allowing you to access the best of farm-fresh
                                                produce with just a few clicks. Convenience should never come at the cost of quality, and at
                                                Harvest Fresh, it never does.</p>
                                        </li>
                                        <li>
                                            <input type="checkbox" checked={check['5']} onChange={() => handleCheck(5)} />
                                            <i></i>
                                            <h2>Cultivating a Greener Tomorrow</h2>
                                            <p>Harvest Fresh is dedicated to environmental responsibility. We are committed to eco-
                                                friendly farming practices that minimize our ecological footprint. Our mission is to
                                                contribute to a greener, more sustainable future by prioritizing environmental conservation
                                                and biodiversity.</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
            <Footer />
        </div >
    )
}

export default About