import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  CartSelector,
  addcartProduct,
  cartProductList,
  removecartProduct,
} from "../../store/reducer/CartReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
// import LeafImg from "../../assets/img/leaf.png";
import { toast } from "react-toastify";
import { API_STATUS, PAY_METHOD } from "../../utils/Constant";
import {
  checkOut,
  clearLoading,
  displayRazorPay,
  productSelector,
} from "../../store/reducer/ProductReducer";
import {
  getUser,
  homeSelector,
  settingData,
} from "../../store/reducer/HomeReducer";

const CartProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tax_amount, setTax] = useState();
  const [payMethod, setPayMethod] = useState({
    cod: true,
    online: false,
  });
  const [total_amount, setTotal] = useState();
  const {
    cartProductDatas,
    cartProducttotal,
    addcartLoading,
    RazorpayLoader,
    removecartLoading,
    errorMessage,
    clearLoadings,
  } = useSelector(CartSelector);
  const { paymentpayLoader, SucessPaymentLoading, paymentpayLoad } =
    useSelector(productSelector);
  const { settingDatas, userData } = useSelector(homeSelector);
  console.log(userData, "userData");

  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";
    dispatch(cartProductList({ page, limit, query }));
    dispatch(settingData({}));
    dispatch(getUser());
  }, []);

  useEffect(() => {
    let taxVal = settingDatas?.tax ? settingDatas?.tax : 18;
    let taxAmts = ((cartProducttotal / 100) * parseFloat(taxVal)).toFixed();
    setTax(taxAmts);
    let cartTotal = (
      parseFloat(cartProducttotal) + parseFloat(taxAmts)
    ).toFixed();
    setTotal(cartTotal);
  }, [cartProducttotal]);

  const decreseQty = (productData) => {
    if (productData.quantity > 1) {
      const data = [];
      let addData = {};
      let Qty = "-1";
      addData.product_id = productData.product_id;
      addData.quantity = Qty;
      addData.category_id = productData.category_id;
      addData.reg_price = productData.reg_price;
      addData.sale_price = productData.sale_price;
      data.push(addData);
      dispatch(addcartProduct({ payload: data }));
    } else {
      let cartId = {};
      cartId.id = productData.id;
      console.log(cartId, "cartId");
      dispatch(removecartProduct({ cartId }));
    }
  };

  const increseQty = (productData) => {
    const data = [];
    let addData = {};
    addData.product_id = productData.product_id;
    addData.quantity = "1";
    addData.category_id = productData.category_id;
    addData.reg_price = productData.reg_price;
    addData.sale_price = productData.sale_price;
    data.push(addData);
    dispatch(addcartProduct({ payload: data }));
  };

  const handleCheckOut = () => {
    const isLive = false;
    if(isLive){
        console.log(userData?.datas?.address, "ADDREs");
        if (userData?.datas?.address) {
          let datas = {
            total_price: total_amount,
            sub_total: cartProducttotal,
            tax_price: tax_amount,
            cartDetails: {
              id: cartProductDatas.map((items) => {
                return items.id;
              }),
            },
          };
          if (payMethod.cod) {
            datas.type = PAY_METHOD.COD;
            dispatch(checkOut({ datas }));
          } else if (payMethod.online) {
            datas.type = PAY_METHOD.ONLINE;
            dispatch(displayRazorPay({ datas, userData }));
          }
        } else {
          toast.dismiss();
          toast.error("Plase Update the Address to Checkout!");
          navigate("/my_account");
        }
    }else{
        toast.error("Temporarily Unavailable to Checkout");
    }
   
  };

  const removeProduct = (id) => {
    let cartId = {};
    cartId.id = id;
    console.log(cartId, "cartId");
    dispatch(removecartProduct({ cartId }));
  };

  const handlePayCOD = (e) => {
    setPayMethod({
      cod: !payMethod.cod,
      online: !payMethod.online,
    });
  };

  const handlePayOnline = (e) => {
    setPayMethod({
      cod: !payMethod.cod,
      online: !payMethod.online,
    });
  };

  useEffect(() => {
    if (removecartLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
    }
    if (RazorpayLoader === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
      dispatch(clearLoading());
    }
    if (SucessPaymentLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
      navigate("/my_orders");
      toast.success("Your Order is Confirmed");
      dispatch(clearLoading());
    }
    if (paymentpayLoader === API_STATUS.FULFILLED) {
      navigate("/my_orders");
      toast.success("Your Order is Confirmed");
      dispatch(clearLoading());
    }
    if (addcartLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
    }
    if (errorMessage?.message) toast.error(errorMessage?.message);
    else if (errorMessage?.errors) {
      let data = "";
      console.log(errorMessage, "errorMessage");
      errorMessage?.errors.map((err) => {
        data += err.message + " ";
      });
      toast.error(data);
      dispatch(clearLoadings());
    }
  }, [
    removecartLoading,
    addcartLoading,
    errorMessage,
    RazorpayLoader,
    paymentpayLoader,
    paymentpayLoad,
    SucessPaymentLoading,
  ]);

  return (
    <>
      <Header />

      {/* <!-- HERO SECTION PART START --> */}
      <div class="hero_section">
        <div class="png_img">
          {/* <img class="img-fluid" src={} alt="" /> */}
        </div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="herosection_content">
                <h2>Cart Overview</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- SECTION START  --> */}

      {/* <!-- CART OVERVIEW PART START --> */}
      <div class="cart_overview">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 table-responsive">
              <table class="table">
                <thead>
                  <tr class="cartoverview_title">
                    <th>IMAGES</th>
                    <th>PRODUCT</th>
                    <th>PRICE</th>
                    <th>QUANTITY</th>
                    <th>TOTAL</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cartProductDatas &&
                    cartProductDatas.map((row, index) => {
                      return (
                        <tr>
                          <th scope="row">
                            <div class="thamnail_img">
                              {/* <img class="img-fluid" src="img/overviewimg1.jpg" alt="" /> */}
                              <img
                                class="w-100"
                                src={API_BASE + row?.product?.image}
                                alt=""
                                height="50px"
                                width="50px"
                              />
                            </div>
                          </th>
                          <td class="align-middle">
                            <b>{row?.product?.name}</b>
                          </td>
                          <td class="align-middle">{row?.sale_price}</td>

                          <td class="align-middle">
                            <div class="cart d-flex">
                              <div class="number-input">
                                <button
                                  onClick={() => decreseQty(row)}
                                ></button>
                                <input
                                  class="quantity"
                                  min="0"
                                  name="quantity"
                                  value={row?.quantity}
                                  type="number"
                                />
                                <button
                                  onClick={() => increseQty(row)}
                                  class="plus"
                                ></button>
                              </div>
                            </div>
                          </td>
                          <td class="align-middle">₹{row?.total_sale_price}</td>
                          <td class="align-middle">
                            <i
                              class="icofont-close text-danger"
                              onClick={() => removeProduct(row?.id)}
                            ></i>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- CART OVERVIEW PART END --> */}

      {/* <!-- COUPON PART START --> */}
      <div class="coupon_part">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <div class="serach_btn">
                {/* <form action="#">
                                    <div class="search_ber">
                                        <input type="text" class="form-control search_button" id="serach_button" name="serach_button" value="" placeholder="Apply Coupon" />
                                        <div class="coupon_btn">
                                            <a href={null} style={{ borderRadius: "5px" }}>APPLY</a> */}
                {/* <a href={null} class="btn border-radius-0 border-transparent">APPLY COUPON</a> */}
                {/* </div>
                                    </div>
                                </form> */}
              </div>
              <div className="payment_type">
                <h3 className="mb-2">Confirm Payment:</h3>
                <label for="cod">
                  <input
                    type="radio"
                    id="cod"
                    name="payment_type"
                    value="cod"
                    checked={payMethod.cod}
                    onClick={handlePayCOD}
                  />{" "}
                  Cash on Delivery
                </label>

                <label for="razorpay">
                  <input
                    type="radio"
                    id="razorpay"
                    name="payment_type"
                    value="razorpay"
                    checked={payMethod.online}
                    onClick={handlePayOnline}
                  />{" "}
                  Online payment
                </label>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <div class="order_cardTotal">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="card_total text-uppercase"
                        colspan="2"
                      >
                        Cart total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" class="subtotal">
                        Subtotal:
                      </th>
                      <td class="text-right">₹{cartProducttotal}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="discount">
                        Tax:
                      </th>
                      <td class="text-right">₹{tax_amount}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total:</th>
                      <td class="text-right total_num">₹{total_amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {total_amount > 0 ? (
                <div class="chechout_btn text-right">
                  <a
                    href={null}
                    onClick={handleCheckOut}
                    class="btn border-radius-0 border-transparent"
                  >
                    Checkout
                  </a>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- COUPON PART END --> */}
      {/* <!-- SECTION END --> */}
      <Footer />
    </>
  );
};

export default CartProducts;
