import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Pack1 from "../../assets/img/pack-1.png";
import banner1 from "../../assets/img/banner-1.png";
import banner2 from "../../assets/img/banner-2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { bannerList, homeSelector } from "../../store/reducer/HomeReducer";
import { API_BASE } from "../../services/config";
import { Link } from "react-router-dom";
import { Slide } from "react-toastify";
import GFront from "../../assets/img/GFront.jpg";
import BFront from "../../assets/img/BFront.jpg";
import DFront from "../../assets/img/DFront.jpg";
import VFront from "../../assets/img/VFront.jpg";
import LFront from "../../assets/img/LFront.jpg";
import {
  filterCategoryByIdData,
  productSelector,
} from "../../store/reducer/ProductReducer";
const Banner = () => {
  const dispatch = useDispatch();
  const { bannerdata } = useSelector(homeSelector);
  const { productFilterDatas, productById } = useSelector(productSelector);
  console.log(productById, "kou67");

  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    arrows: false,
    dots: true,
  };
  const [data, setDatas] = useState([]);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState("");
  const [limit, setLimit] = useState("");

  useEffect(() => {
    dispatch(bannerList({ page, limit, query }));
    let payload = {
      category_id: 21,
    };
    dispatch(filterCategoryByIdData({ payload }));
  }, []);

  useEffect(() => {
    const element = bannerdata[0]?.content?.split(",");
    const q_ele = bannerdata[0]?.quote?.split(",");
    const des_ele = bannerdata[0]?.description?.split(",");
    for (let index = 0; index < element?.length; index++) {
      let data = {};
      data.content = element[index];
      data.quote = q_ele[index];
      data.description = des_ele[index];
      setDatas((prev) => [...prev, data]);
    }
  }, [bannerdata]);

  return (
    <>
      <div class="full-banner">
        {/* <div class="row banner-slider"> */}
        <Slider {...settings}>
          {data?.map((values, i) => {
            let spl = values.quote.split(" ");
            if (spl.length > 1) {
              spl = [spl[0], spl.slice(1).join(" ")];
            }
            // if (i === 0)
            return (
              <>
                <div class="col-md-12">
                  <div class="banner-content">
                    <h1>
                      {spl[0]} <span>{spl[1]}</span>
                    </h1>
                    <h3>{values?.content}</h3>
                    <p>{values?.description}</p>
                    <Link to={"/products"} class="btn">
                      All Products
                    </Link>
                  </div>
                </div>
              </>
            );
          })}
        </Slider>
        {/* </div> */}

        <section className="banner">
          <Slider {...settings}>
            <div className="banner_1">
              <img src={banner1} alt="" />
            </div>
            <div className="banner_1">
              <img src={banner2} alt="" />
            </div>
          </Slider>
        </section>

        <div className="flex jsb bundles">
          {/* {productById?.map((ele)=>{
              return(
                <div className="cols-3">
                <div className="bg crk_1">
                  <Link to={"/product_category"}><img src={API_BASE+ele?.image} alt="" /></Link>
                </div>
              </div>

              )
            })} */}

          <div className="cols-3">
            <div className="bg crk_3">
              <Link to="product_category/23">
                <img src={GFront} alt="" />
              </Link>
            </div>
          </div>
          <div className="cols-3">
            <div className="bg crk_3">
              <Link to="product_category/21">
                <img src={BFront} alt="" />
              </Link>
            </div>
          </div>

          <div className="cols-3">
            <div className="bg crk_2">
              <Link to="product_category/22">
                <img src={DFront} alt="" />
              </Link>
            </div>
          </div>

          <div className="cols-3">
            <div className="bg crk_4">
              <Link to="product_category/25">
                <img src={VFront} alt="" />
              </Link>
            </div>
          </div>

          <div className="cols-3">
            <div className="bg crk_4">
              <Link to="product_category/24">
                <img src={LFront} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
