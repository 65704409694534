import React, { useEffect, useRef, useState } from 'react'
import Header from '../Layout/header';
import Footer from '../Layout/footer';
import Banner from './Banner';
import BestSeller from './BestSeller';
import Features from './Features';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Testimonial1 from "../../assets/img/veg.jpg"
// import news from "../../assets/img/news2.jpg"
// import offerImage from "../../assets/img/fruits.png"
// import About from "../../assets/img/Healthy-Food-Diet-Transparent.png"
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { offerList, productSelector } from '../../store/reducer/ProductReducer';

const Index = () => {
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const dispatch = useDispatch();
    const settings = {
        speed: 500,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.testimonial-text',
        centerMode: true,
        centerPadding: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    centerPadding: 0,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    centerPadding: 0,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    focusOnSelect: true,
                    centerPadding: 0,
                }
            }
        ]
    };
    const { offerData } = useSelector(productSelector);
    const setting = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        arrows: true,
        centerPadding: 0,
        dots: false,
        speed: 1000,
        asNavFor: '.testimonial-img',
        prevArrow: '<i class="icofont-double-right"></i>',
        nextArrow: '<i class="icofont-double-left"></i>',

        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    useEffect(() => {
        dispatch(offerList({ id: 1 }))
    }, [])

    return (
        <>
            <Header />
            <Banner />

            <BestSeller />
        
            {/* <!-- CONTACT NOW PART END --> */}
            <div class="contact-now">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="contact-quote">If you Need crackers at discounted price!</h3>
                            {/* <a href={null} class="btn">Contact Now <i class="icofont-bubble-right"></i></a> */}
                            <Link to={'/contact_us'} class="btn">Contact Now <i class="icofont-bubble-right"></i></Link>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- CONTACT NOW PART END --> */}
            {/* <div class="full-latest-news">
                <div class="container">
                    <div class="row">
                        <div class="col-12 text-center">
                            <h3 class="section-title">Latest News</h3>
                            <p class="section-subtitle">The passage experienced a surge in popularity during the 1960s when again during the 90s as desktop publishers</p>
                        </div>
                    </div>

                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="latest-news-grid">
                                <div class="news-img">
                                    <img class="w-100" src={news1} alt="" />
                                </div>

                                <div class="news-content">
                                    <div class="date-react">
                                        <span class="date">16 January’20</span>
                                        <span class="react"><i class="icofont-ui-love"></i> 56</span>
                                        <span class="react"><i class="icofont-speech-comments"></i> 98</span>
                                    </div>
                                    <div class="news-title">
                                        <a href="blogdetails.html"><h4>The passage experienced a popularity during the used it on their dry-transfer sheets.</h4></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="latest-news-list d-flex">
                                <div class="news-img">
                                    <img class="w-100" src={news} alt="" />
                                </div>

                                <div class="news-content">
                                    <div class="date-react">
                                        <span class="date">16 January’20</span>
                                        <span class="react"><i class="icofont-ui-love"></i> 56</span>
                                        <span class="react"><i class="icofont-speech-comments"></i> 98</span>
                                    </div>
                                    <div class="news-title">
                                        <a href="blogdetails.html"><h4>The passage popularity dry transfer sheets.</h4></a>
                                    </div>
                                </div>
                            </div>

                            <div class="latest-news-list d-flex">
                                <div class="news-img">
                                    <img class="w-100" src={news} alt="" />
                                </div>

                                <div class="news-content">
                                    <div class="date-react">
                                        <span class="date">16 January’20</span>
                                        <span class="react"><i class="icofont-ui-love"></i> 56</span>
                                        <span class="react"><i class="icofont-speech-comments"></i> 98</span>
                                    </div>
                                    <div class="news-title">
                                        <a href="blogdetails.html"><h4>The passage popularity dry transfer sheets.</h4></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div> */}
            <Footer />
        </>
    )
};

export default Index