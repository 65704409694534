import React, { useRef, useState, useEffect, useCallback } from "react";

import Header from "./Layout/header";
import Footer from "./Layout/footer";
import { Link, useNavigate, useParams } from "react-router-dom";

import Pack1 from "../assets/img/pack-1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  filterCategoryByIdData,
  productSelector,
} from "../store/reducer/ProductReducer";
import {
  MyOrderDetails,
  settingData,
  homeSelector,
  clearMessages,
} from "../store/reducer/HomeReducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { myOrderSchema } from "../utils/Validation";
import { API_BASE } from "../services/config";
import { toast } from "react-toastify";

const ProductCategory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productById } = useSelector(productSelector);
  let [choseProducts, setChoseProducts] = useState([]);
  console.log(productById, "productDatas21");
  const [open, setOpen] = React.useState(false);
  const [total_amount, settotal_amount] = useState(0);
  const [total_qty, settotal_qty] = useState(0);
  const [total_prd, settotal_prd] = useState(0);
  const [orderplace, setOrderplace] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(false);
  };

  const handleMouseLeave = () => {
    setHovered(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(myOrderSchema),
  });
  const submitHandler = (formData) => {
    console.log(formData, "Formdata MyAccount accounDetails");
    const ignoreDatas = [
      "created_at",
      "name",
      "image",
      "created_by",
      "updated_at",
      "updated_by",
    ];
    const ignorekeys = Object.keys(formData);
    for (let index = 0; index < ignorekeys.length; index++) {
      const element = ignorekeys[index];
      if (ignoreDatas.includes(element)) {
        console.log(element);
        delete formData[element];
      }
    }
    const filteredProducts = choseProducts.filter(
      (product) => product.quantity > 0
    );
    formData.products = filteredProducts;
    formData.total_price = total_amount;
    console.log(formData, "formData");
    if (total_amount <= 2499) {
      toast.error("Minimum purchase amount is 2500!");
      return;
    }
    // let date = formData.dob;
    // formData.dob = dayjs(date).format("YYYY-MM-DD");
    dispatch(MyOrderDetails({ formData }));
  };

  const dataImage = (filename) => {
    const baseName = filename.substring(0, filename.lastIndexOf(".")); // Get the base name
    const extension = filename.substring(filename.lastIndexOf(".")); // Get the extension
    return `${API_BASE}${baseName}-1${extension}`; // Append -1 before the extension
  };
  React.useEffect(() => {
    let payload = {
      category_id: id,
    };
    dispatch(filterCategoryByIdData({ payload }));
  }, []);
  return (
    <div>
      <Header />
      <section className="Products_cat mb-5">
        <div className="flex jsb">
          {productById?.map((ele) =>
            (ele.catgory_id == id) ? (
              <div className="cols-4" key={ele?.id}>
              <Link to={`/bundle/${ele?.id}/${ele?.category?.id}`}>
                <div
                  className="bg crk_4"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <img
                    src={
                      hovered
                        ? `${API_BASE}${ele?.image}`
                        : dataImage(ele?.image)
                    }
                    alt=""
                  />
                </div>
              </Link>
                   
              <div className="col text-center mt-3">
                    {/* <input
                      type="checkbox"
                      onChange={(e) => {
                        // setChoseProducts([]);
                        if (e.target.checked) {
                          navigate(`/bundle/${ele?.id}/${ele?.category?.id}`);
                          // dispatch(clearproductDat)
                        }
                      }}
                    /> */}
                    {/* <label>Select Bundle</label> */}
                    <h3>{ele?.name}</h3>
                    <a href={dataImage(ele?.image)} target="_blank" className="view_btn">View Items</a>
                  </div> 
              </div>
            ) : (
              ""
            )
          )}

          {/* <div className="cols-4">
            <div className="bg crk_4">
              <Link>
                <img src={Pack1} alt="" />
              </Link>
            </div>
          </div>
          <div className="cols-4">
            <div className="bg crk_4">
              <Link>
                <img src={Pack1} alt="" />
              </Link>
            </div>
          </div>
          <div className="cols-4">
            <div className="bg crk_4">
              <Link>
                <img src={Pack1} alt="" />
              </Link>
            </div>
          </div> */}
        </div>
      </section>
      {/* <div class="col-lg-12">
        <div class="container">
          <div class="row justify-content-between">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9">
              <div class="profile_form">
                <form onSubmit={handleSubmit(submitHandler)}>
                  <div class="form_group d-flex">
                    <div class="col-md-12 form_field">
                      <label for="first_name">Name</label>
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        {...register("first_name")}
                      />
                      <div className="mt-2 p-1">
                        {errors?.first_name?.message && (
                          <p className="error">{errors.first_name.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form_group d-flex">
                    <div class="col-md-6 form_field">
                      <label for="mobile">Phone</label>
                      <input
                        type="text"
                        name="mobile"
                        className="form-control"
                        {...register("mobile")}
                      />
                      <div className="mt-2 p-1">
                        {errors?.mobile?.message && (
                          <p className="error">{errors.mobile.message}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6 form_field">
                      <label for="email">Email</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        {...register("email")}
                      />
                      <div className="mt-2 p-1">
                        {errors?.email?.message && (
                          <p className="error">{errors.email.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form_group d-flex">
                    <div class="col-md-6 form_field">
                      <label for="city">City</label>
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        {...register("city")}
                      />
                      <div className="mt-2 p-1">
                        {errors?.city?.message && (
                          <p className="error">{errors.city.message}</p>
                        )}
                      </div>
                    </div>
                    <div class="col-md-6 form_field">
                      <label for="zipcode">PIN Code</label>
                      <input
                        type="text"
                        name="zipcode"
                        className="form-control"
                        {...register("zipcode")}
                      />
                      <div className="mt-2 p-1">
                        {errors?.zipcode?.message && (
                          <p className="error">{errors.zipcode.message}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div class="form_group col-full d-flex">
                    <div class="col-md-12">
                      <div class="form_field">
                        <label for="address">Address</label>
                        <textarea
                          style={{ color: "black" }}
                          className="form-control"
                          name="address"
                          {...register("address")}
                        ></textarea>
                        <div className="mt-2 p-1">
                          {errors?.address?.message && (
                            <p className="error">{errors.address.message}</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form_group flex justify-flex-start">
                    <div class="chechout_btn text-right">
                      <input
                        type="submit"
                        value="Submit"
                        name="submit"
                        disabled={orderplace}
                        class="btn border-radius-0 "
                        style={{
                          background:
                            "linear-gradient(to right, #0f7404, #5cb917)",
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
              <div class="order_cardTotal">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        class="card_total text-uppercase"
                        colspan="2"
                      >
                        Cart total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" class="subtotal">
                        Total quantity:
                      </th>
                      <td class="text-right">{total_qty}</td>
                    </tr>
                    <tr>
                      <th scope="row" class="discount">
                        Total Products:
                      </th>
                      <td class="text-right">{total_prd}</td>
                    </tr>
                    <tr>
                      <th scope="row">Total Amount:</th>
                      <td class="text-right total_num">₹{total_amount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};

export default ProductCategory;
