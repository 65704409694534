import React, { useRef, useState, useEffect, useCallback } from "react";

import Header from "./Layout/header";
import Footer from "./Layout/footer";
import { Link, useNavigate, useParams } from "react-router-dom";

import Pack1 from "../assets/img/pack-1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  filterCategoryByIdData,
  productSelector,
} from "../store/reducer/ProductReducer";
import {
  MyOrderDetails,
  settingData,
  homeSelector,
  clearMessages,
} from "../store/reducer/HomeReducer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { myOrderSchema } from "../utils/Validation";
import { API_BASE } from "../services/config";
import { toast } from "react-toastify";
import GFront from "../assets/img/GFront.jpg";
import BFront from "../assets/img/BFront.jpg";
import DFront from "../assets/img/DFront.jpg";
import VFront from "../assets/img/VFront.jpg";
import LFront from "../assets/img/LFront.jpg";

const Bundle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productById } = useSelector(productSelector);
  let [choseProducts, setChoseProducts] = useState([]);
  console.log(productById, "productDatas21");
  const [open, setOpen] = React.useState(false);
  const [total_amount, settotal_amount] = useState(0);
  const [total_qty, settotal_qty] = useState(0);
  const [total_prd, settotal_prd] = useState(0);
  const [orderplace, setOrderplace] = useState(false);
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(false);
  };

  const handleMouseLeave = () => {
    setHovered(true);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(myOrderSchema),
  });
  const submitHandler = (formData) => {
    console.log(formData, "Formdata MyAccount accounDetails");
    const ignoreDatas = [
      "created_at",
      "name",
      "image",
      "created_by",
      "updated_at",
      "updated_by",
    ];
    const ignorekeys = Object.keys(formData);
    for (let index = 0; index < ignorekeys.length; index++) {
      const element = ignorekeys[index];
      if (ignoreDatas.includes(element)) {
        console.log(element);
        delete formData[element];
      }
    }
    const filteredProducts = choseProducts.filter(
      (product) => product.quantity > 0
    );
    formData.products = filteredProducts;
    formData.total_price = total_amount;
    console.log(formData, "formData");
    if (total_amount <= 2499) {
      toast.error("Minimum purchase amount is 2500!");
      return;
    }
    // let date = formData.dob;
    // formData.dob = dayjs(date).format("YYYY-MM-DD");
    dispatch(MyOrderDetails({ formData }));
  };

  const dataImage = (filename) => {
    const baseName = filename.substring(0, filename.lastIndexOf(".")); // Get the base name
    const extension = filename.substring(filename.lastIndexOf(".")); // Get the extension
    return `${API_BASE}${baseName}-1${extension}`; // Append -1 before the extension
  };
  React.useEffect(() => {
    let payload = {
      category_id: id,
    };
    dispatch(filterCategoryByIdData({ payload }));
  }, []);
  return (
    <div>
      <Header />
      <section className="Products_cat ">
        
      <div className="flex jsb">
          {/* {productById?.map((ele)=>{
              return(
                <div className="cols-3">
                <div className="bg crk_1">
                  <Link to={"/product_category"}><img src={API_BASE+ele?.image} alt="" /></Link>
                </div>
              </div>

              )
            })} */}

          <div className="cols-4 mb-3">
            <div className="bg crk_3">
              <Link to="/product_category/23">
                <img src={GFront} alt="" />
              </Link>
            </div>
          </div>
          <div className="cols-4 mb-3">
            <div className="bg crk_3">
              <Link to="/product_category/21">
                <img src={BFront} alt="" />
              </Link>
            </div>
          </div>

          <div className="cols-4 mb-3">
            <div className="bg crk_2">
              <Link to="/product_category/22">
                <img src={DFront} alt="" />
              </Link>
            </div>
          </div>

          <div className="cols-4 mb-3">
            <div className="bg crk_4">
              <Link to="/product_category/25">
                <img src={VFront} alt="" />
              </Link>
            </div>
          </div>

          <div className="cols-4 mb-3">
            <div className="bg crk_4">
              <Link to="/product_category/24">
                <img src={LFront} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Bundle;
