import * as yup from "yup";


const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const signUpValidationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    // surname: yup.string().required("Surname is required"),
    email1: yup
        .string()
        .required("Email is required")
        .test("email", "Email is invalid", (value) => {
            return validateEmail(value);
        }),
    // phone_number: yup
    //     .string()
    //     .required("Phone number is required")
    //     .test("phone", "Phone is invalid", (value) => {
    //         return validatePhone(parseInt(value ?? "0"));
    //     }),
    password1: yup
        .string()
        .required("Password is required")
        .matches(passwordRules, { message: "Please create a stronger password" }),
});

export const myAccountSchema = yup.object().shape({
    first_name: yup.string().required("Firstname is required"),
    last_name: yup.string().required("Lastname is required"),
    dob: yup.date().required("DOB is required"),
    gender: yup.string().required("Gender is required"),
    mobile: yup.string().required("Phone is required"),
    city: yup.string().required("City is required"),
    zipcode: yup.string().required("Pin Code is required"),
    email: yup
        .string()
        .required("Email is required")
        .test("email", "Email is invalid", (value) => {
            return validateEmail(value);
        }),
    address: yup.string().required("Address is required")
});


export const myOrderSchema = yup.object().shape({
    first_name: yup.string().required("Firstname is required"),
    mobile: yup.number().required("Phone is required"),
    city: yup.string().required("City is required"),
    zipcode: yup.number().required("Pin Code is required"),
    email: yup
        .string()
        .required("Email is required")
        .test("email", "Email is invalid", (value) => {
            return validateEmail(value);
        }),
    address: yup.string().required("Address is required"), 
    area: yup.string()
    .oneOf(
      ["Signallur", "Ramanathapuram", "Kovaipudur", "Selvapuram"], 
      "Invalid area selection"
    )
    .required("Area is required"),
});

export const contactUsValidationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup
        .string()
        .required("Email is required")
        .test("email", "Email is invalid", (value) => {
            return validateEmail(value);
        }),
    phone_number: yup
        .string()
        .required("Phone number is required")
        .test("phone", "Phone is invalid", (value) => {
            return validatePhone(parseInt(value ?? "0"));
        }),
    message: yup
        .string()
});

export const signInValidationSchema = yup.object().shape({
    // name: yup.string().required("Name is required"),
    email: yup
        .string()
        .required("Email is required")
        .test("email", "Email is invalid", (value) => {
            return validateEmail(value);
        }),
    // phone_number: yup
    //     .string()
    //     .required("Phone number is required")
    //     .test("phone", "Phone is invalid", (value) => {
    //         return validatePhone(parseInt(value ?? "0"));
    //     }),
    password: yup
        .string()
        .required("Password is required")
        .matches(passwordRules, { message: "Please create a stronger password" }),
});

const validateEmail = (email) => {
    return yup.string().email().isValidSync(email);
};

const validatePhone = (phone) => {
    return yup
        .number()
        .integer()
        .positive()
        .test((phone) => {
            return phone &&
                phone.toString().length >= 8 &&
                phone.toString().length <= 14
                ? true
                : false;
        })
        .isValidSync(phone);
};