import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../utils/Constant";
// import { categories, offers, products, razorPay, searchFilterProduct } from "../../services/api";
import { categories, products, searchFilterProduct, razorPay, getProductById, productsByCategory, offers, verifyPayment, failPayment, cateByProduct } from "../../services/api";
import EncryptDecrypt, { decryptdata } from "./../../utils/encrypt&decrypt";
import { loadScript, secretKey } from "../../services/config";
import { cartProductList } from "./CartReducer";
import Logo from "../../assets/img/logo.png"
import { toast } from "react-toastify";

const namespace = "ProductReducer";

const initialState = {
    loading: "initial",
    errorMessage: "",
    productLoading: "initial",
    categoryLoading: "initial",
    productdetailsLoading: "initial",
    offerLoading: "initial",
    RazorpayLoader: "initial",
    paymentpayLoader: "initial",
    paymentpayLoad: "initial",
    SucessPaymentLoading: "initial",
    count: 0,
    categoryDatas: [],
    productDatas: [],
    productFilterDatas: [],
    productById:[],
    productIdDatas: [],
    relatedProductDatas: [],
    offerData: []
};

export const categoryList = createAsyncThunk(
    `${namespace}/categoryList`,
    async ({ page, limit, query }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await categories(page, limit, query);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const filterData = createAsyncThunk(
    `${namespace}/filterData`,
    async ({ filterArray }, { rejectWithValue, dispatch }) => {
        try {
            console.log({ filterArray }, "quruiesfilterData");
            const { data } = await searchFilterProduct(filterArray);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const filterCategoryByIdData = createAsyncThunk(
    `${namespace}/filterCategoryByIdData`,
    async ({ payload }, { rejectWithValue, dispatch }) => {
        try {
            console.log( payload , "quruiessfilterData");
            const { data } = await cateByProduct(payload);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);


export const productList = createAsyncThunk(
    `${namespace}/productList`,
    async ({ page, limit, query }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await products(page, limit, query);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const productdataDetails = createAsyncThunk(
    `${namespace}/productdataDetails`,
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await getProductById(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const relatedProducts = createAsyncThunk(
    `${namespace}/relatedProducts`,
    async ({ getData }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await productsByCategory(getData);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);
export const offerList = createAsyncThunk(
    `${namespace}/offerList`,
    async ({ id }, { rejectWithValue, dispatch }) => {
        try {
            console.log('payload first offer')
            console.log({ id }, "quruiesfilterData");
            const { data } = await offers(id);
            return data;
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
);

export const checkOut = createAsyncThunk(
    `${namespace}/checkOut`,
    async ({ datas }, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await razorPay(datas)
            return data
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)

export const displayRazorPay = createAsyncThunk(
    `${namespace}/displayRazorPay`,
    async ({ datas, userData }, { rejectWithValue, dispatch }) => {

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res) {
            alert('Razropay failed to load!!')
            return
        }
        datas.total_price = `${Number(datas.total_price) * 100}`
        const data = await razorPay(datas);

        console.log(data)
        let order_id = data?.data?.order?.id;
        let amount = data?.data?.order?.amount_due

        const options = {
            key: process.env.LIVE_KEY_ID,
            amount: `${amount}`,
            currency: "INR",
            name: "Harvest Fresh",
            description: "Transaction",
            image: Logo,
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    ...datas
                };

                dispatch(SucessPayment(data))
                // const result = await verifyPayment(data);
                // if (result) {
                //     dispatch(cartProductList({ page: 0, limit: 0, query: "" }));
                //     setIsPaySuccess(true)
                // }

            },
            prefill: {
                name: userData?.datas?.name,

            },
            notes: {
                address: userData?.datas?.address,
            },
            theme: {
                color: "#61dafb",
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", async function (response) {
            await failPayment({ data: order_id, cartDetails: datas?.cartDetails })

        });
        paymentObject.open()
    });

const SucessPayment = createAsyncThunk(
    `${namespace}/SucessPayment`,
    async (datas, { rejectWithValue, dispatch }) => {
        try {
            const { data } = await verifyPayment(datas)
            return data
        } catch (error) {
            return rejectWithValue(error.response);
        }
    }
)






const productSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearproductData: () => {
            return initialState;
        },
        clearcategoryData: () => {
            return initialState;
        },
        clearLoading: state => {
            state.productLoading = "initial";
            state.categoryLoading = "initial";
            state.productdetailsLoading = "initial";
            state.offerLoading = "initial";
            state.RazorpayLoader = "initial";
            state.paymentpayLoader = "initial";
            state.paymentpayLoad = "initial";
            state.productIdDatas = [];
            state.SucessPaymentLoading = "initial";
        }
    },
    extraReducers: (builder) => {
        builder.addCase(categoryList.pending, (state) => {
            state.categoryLoading = API_STATUS.PENDING;
        })
        builder.addCase(categoryList.fulfilled, (state, { payload }) => {
            state.categoryLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(
                EncryptDecrypt.decryptdata(payload.datas, secretKey)
            );
            console.log(payloaddatas, 'payloaddatas');
            state.categoryDatas = payloaddatas?.data;
        })
        builder.addCase(categoryList.rejected, (state, action) => {
            state.categoryLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(productList.pending, (state) => {
            state.productLoading = API_STATUS.PENDING;
        })
        builder.addCase(productList.fulfilled, (state, { payload }) => {
            state.productLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(
                EncryptDecrypt.decryptdata(payload.datas, secretKey)
            );
            console.log(payloaddatas, 'productspayloaddatas');
            state.productDatas = payloaddatas?.data;
        })
        builder.addCase(productList.rejected, (state, action) => {
            state.productLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(SucessPayment.rejected, (state, action) => {
            state.SucessPaymentLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        })
        builder.addCase(SucessPayment.fulfilled, (state, action) => {
            state.SucessPaymentLoading = API_STATUS.FULFILLED;
        })
        builder.addCase(SucessPayment.pending, (state, action) => {
            state.SucessPaymentLoading = API_STATUS.PENDING;
        })
        builder.addCase(filterData.pending, (state) => {
            state.searchLoading = API_STATUS.PENDING;
        });
        builder.addCase(filterData.fulfilled, (state, { payload }) => {
            state.searchLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(
                EncryptDecrypt.decryptdata(payload.datas, secretKey)
            );
            console.log(payloaddatas, 'payloaddatas');
            state.productFilterDatas = payloaddatas?.data?.rows;
            // state.productFilterDatas = payload?.data?.rows;
            console.log(payload, "state.productFilterDatas");
        });
        builder.addCase(filterData.rejected, (state, action) => {
            state.searchLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        });
        builder.addCase(filterCategoryByIdData.pending, (state) => {
            state.searchLoading = API_STATUS.PENDING;
        });
        builder.addCase(filterCategoryByIdData.fulfilled, (state, { payload }) => {
            state.searchLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(
                EncryptDecrypt.decryptdata(payload.datas, secretKey)
            );
            console.log(payloaddatas, 'payloaddatas');
            state.productById = payloaddatas?.data;
            // state.productFilterDatas = payload?.data?.rows;
            console.log(payload, "state.productFilterDatas");
        });
        builder.addCase(filterCategoryByIdData.rejected, (state, action) => {
            state.searchLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        });

        builder.addCase(productdataDetails.pending, (state) => {
            state.searchLoading = API_STATUS.PENDING;
        });
        builder.addCase(productdataDetails.fulfilled, (state, { payload }) => {
            state.searchLoading = API_STATUS.FULFILLED;
            let payloaddatas = JSON.parse(
                EncryptDecrypt.decryptdata(payload.datas, secretKey)
            );
            console.log(payloaddatas, 'payloaddatas');
            state.productIdDatas = payloaddatas?.response;
            // state.productIdDatas = payload?.data?.rows;
            // console.log(payload, "state.productIdDatas");
            // console.log(state.productIdDatas, "state.productIdDatas");
        });
        builder.addCase(productdataDetails.rejected, (state, action) => {
            state.searchLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        });
        builder.addCase(relatedProducts.pending, (state) => {
            state.searchLoading = API_STATUS.PENDING;
        });
        builder.addCase(relatedProducts.fulfilled, (state, { payload }) => {
            state.searchLoading = API_STATUS.FULFILLED;
            // let payloaddatas = JSON.parse(
            //     EncryptDecrypt.decryptdata(payload.datas, secretKey)
            // );
            // console.log(payloaddatas, 'payloaddatas');
            // state.relatedProductDatas = payloaddatas?.response;
            state.relatedProductDatas = payload?.datas?.rows;
            console.log(payload, "state.relatedProductDatas");
            // console.log(state.relatedProductDatas, "state.relatedProductDatas");
        });
        builder.addCase(relatedProducts.rejected, (state, action) => {
            state.searchLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        });

        builder.addCase(offerList.pending, (state) => {
            state.offerLoading = API_STATUS.PENDING;
        });
        builder.addCase(offerList.fulfilled, (state, { payload }) => {
            state.offerLoading = API_STATUS.FULFILLED;
            console.log(payload, 'payloadpayloadpayload')
            let payloaddatas = JSON.parse(
                EncryptDecrypt.decryptdata(payload.data, secretKey)
            );
            state.offerData = payloaddatas;
        });
        builder.addCase(offerList.rejected, (state, action) => {
            state.offerLoading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data;
        });
        builder.addCase(displayRazorPay.pending, (state) => {
            state.paymentpayLoad = API_STATUS.PENDING
        })
        builder.addCase(displayRazorPay.fulfilled, (state, { payload }) => {
            // state.paymentpayLoad = API_STATUS.FULFILLED
        })
        builder.addCase(displayRazorPay.rejected, (state, { payload }) => {
            state.paymentpayLoad = API_STATUS.REJECTED
            state.errorMessage = payload?.data;
        })
        builder.addCase(checkOut.pending, (state) => {
            state.paymentpayLoader = API_STATUS.PENDING
        })
        builder.addCase(checkOut.fulfilled, (state, { payload }) => {
            state.paymentpayLoader = API_STATUS.FULFILLED
        })
        builder.addCase(checkOut.rejected, (state, { payload }) => {
            state.paymentpayLoader = API_STATUS.REJECTED
            state.errorMessage = payload?.data;
        })
    },
});

export const { clearproductData, clearcategoryData, clearLoading } = productSlice.actions;

export const productSelector = (state) => state.ProductReducer;

export default productSlice.reducer;
