import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  productList,
  productSelector,
} from "../../store/reducer/ProductReducer";
import { API_BASE } from "../../services/config";
import {
  CartSelector,
  addcartProduct,
  cartProductList,
} from "../../store/reducer/CartReducer";
import { API_STATUS } from "../../utils/Constant";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";

const BestSeller = () => {
  const [query, setQuery] = useState("");
  const [page, setPage] = useState("");
  const [limit, setLimit] = useState(8);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productDatas } = useSelector(productSelector);
  console.log(productDatas, "productDatas21")
  const { addcartLoading, errorMessage } = useSelector(CartSelector);
  useEffect(() => {
    dispatch(productList({ query, page, limit }));
  }, []);
  const addcartProducts = (row) => {
    if (localStorage.getItem("isAuthenticated")) {
      const data = [];
      let addData = {};
      addData.product_id = row.id;
      addData.quantity = "1";
      addData.category_id = row.catgory_id;
      addData.reg_price = row.reg_price;
      addData.sale_price = row.sale_price;
      data.push(addData);
      dispatch(addcartProduct({ payload: data }));
    } else {
      navigate("/signin");
      toast.warning("Signin to continue...");
    }
  };
  useEffect(() => {
    if (addcartLoading === API_STATUS.FULFILLED) {
      toast.dismiss();
      toast.success("Product added in the Cart Successfully!");
      let page = 0;
      let limit = 0;
      let query = "";
      dispatch(cartProductList({ page, limit, query }));
    }
    if (errorMessage?.message) toast.error(errorMessage?.message);
    else if (errorMessage?.errors) {
      let data = "";
      // console.log(errorMessage, "errorMessage");
      errorMessage?.errors.map((err) => {
        data += err.message + " ";
      });
      toast.error(data);
      // dispatch(clearLoadings());
    }
  }, [addcartLoading, errorMessage]);
  console.log(productDatas, "productDatas");
  return (
    <>
      {/* <!-- BEST SELLER PART START --> */}
      <div class="full-bestSeller" id="product">
        <div class="container">
          <div class="row">
            <div class="col-12 text-center">
              <h3 class="section-title">Our Best Products</h3>
              {/* <p class="section-subtitle">The passage experienced a surge in popularity during the 1960s when again during the 90s as desktop publishers</p> */}
            </div>
          </div>

          <div class="row mt-5">
            {productDatas &&
              productDatas?.map((prod) => {
                return (
                  <>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-4">
                      <div class="product">
                        <div class="product-img">
                          <img
                            class="w-100"
                            src={API_BASE + prod?.image}
                            alt={prod?.name}
                          />
                        </div>
                        <div class="product-content">
                          <div class="product-details">
                            <div class="product-name">
                              <Link to={"/products"}>{prod?.name}</Link>
                            </div>
                            <div class="product-flex">
                              <div class="left">
                                <span class="product-prev-price">
                                  ₹{prod?.reg_price}{" "}
                                </span>
                                <span class="product-price">
                                  ₹{prod?.sale_price}{" "}
                                </span>
                              </div>
                              <div class="right">
                                <Link class="btn custom-btn" to="/products">
                                  {" "}
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
      {/* <!-- BEST SELLER PART END --> */}
    </>
  );
};

export default BestSeller;
