import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { myOrderSchema } from "../../utils/Validation";
import dayjs from "dayjs";
import {
  productSelector,
  categoryList,
  productList,
  filterData,
  filterCategoryByIdData,
  productdataDetails,
  clearLoading
} from "../../store/reducer/ProductReducer";
import {
  CartSelector,
  cartProductList,
  removecartProduct,
  addcartProduct,
  clearLoadings,
} from "../../store/reducer/CartReducer";
import {
  MyOrderDetails,
  settingData,
  homeSelector,
  clearMessages,
} from "../../store/reducer/HomeReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/Constant";
// import { homeSelector, settingData } from "../../store/reducer/HomeReducer";

const BundlePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, category_id } = useParams();
  console.log(id, "678sdifu");
  const [range, setRange] = useState(3);
  const [showdata, setshowdata] = useState(0);
  const [tax_amount, settax_amount] = useState(0);
  const [total_amount, settotal_amount] = useState(0);
  const [total_qty, settotal_qty] = useState(0);
  const [total_prd, settotal_prd] = useState(0);
  const [fquery, setFQuery] = useState([]);
  const userData = [];
  let [choseProducts, setChoseProducts] = useState([]);
  const [prices, setPrices] = useState("800");
  const [orderplace, setOrderplace] = useState(false);
  const [quantity_count, setQuantity] = useState(1);
  const rangeRef = useRef(0);
  const rangeVRef = useRef(0);
  const { settingDatas, myOrderLoader, orderData } = useSelector(homeSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(myOrderSchema),
  });
  const submitHandler = (formData) => {
    console.log(formData, "Formdata MyAccount accounDetails");
    const ignoreDatas = [
      "created_at",
      "name",
      "image",
      "created_by",
      "updated_at",
      "updated_by",
    ];
    const ignorekeys = Object.keys(formData);
    for (let index = 0; index < ignorekeys.length; index++) {
      const element = ignorekeys[index];
      if (ignoreDatas.includes(element)) {
        console.log(element);
        delete formData[element];
      }
    }
    const filteredProducts = choseProducts.filter(
      (product) => product.quantity > 0
    );
    formData.products = filteredProducts;
    formData.total_price = total_amount;
    console.log(formData, "formData");
    // let date = formData.dob;
    // formData.dob = dayjs(date).format("YYYY-MM-DD");
    dispatch(MyOrderDetails({ formData }));
  };

  const decreseQty = () => {
    let QtyCount = quantity_count - 1;
    QtyCount > 0 ? setQuantity(QtyCount) : setQuantity(1);
    console.log(QtyCount, "TOASTS");
  };

  const increseQty = () => {
    let QtyCount = quantity_count + 1;
    setQuantity(QtyCount);
    console.log(QtyCount, "TOASTS");
  };

  useEffect(() => {
    setPrices(settingDatas?.max_range ?? "800");
  }, [settingDatas]);

  const {
    categoryDatas,
    productDatas,
    productFilterDatas,
    productLoading,
    catgLoading,
    productById,
    productIdDatas,
  } = useSelector(productSelector);
  const {
    cartProductDatas,
    cartProducttotal,
    cartLoading,
    removecartLoading,
    addcartLoading,
    errorMessage,
  } = useSelector(CartSelector);
  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";

    dispatch(categoryList({ page, limit, query }));
    dispatch(productList({ page, limit, query }));
    dispatch(settingData({}));
    let payload = {
      category_id: 21,
    };
    dispatch(filterCategoryByIdData({ payload }));
  }, []);

  useEffect(() => {
    console.log(categoryDatas, "categoryDatas");
  }, [categoryDatas]);
  console.log(productIdDatas, "produsctById");
  useEffect(() => {
    console.log(productDatas, "productDatas");
  }, [productDatas]);
  useEffect(() => {
    console.log(productFilterDatas, "productFilterDatas");
  }, [productFilterDatas]);

  const handleChange = (event) => {
    setPrices(event.target.value);
    const newValue = Number(
        ((event.target.value - rangeRef.current.min) * 100) /
          (rangeRef.current.max - rangeRef.current.min)
      ),
      newPosition = 10 - newValue * 0.2;
    rangeVRef.current.classList.remove("range-valueX");
    rangeVRef.current.innerHTML = `<span>${event.target.value}</span>`;
    rangeVRef.current.style.left = `calc(${newValue}% + (${newPosition}px))`;
    console.log(event.target.value, "ONPRICES");
    filterFun(fquery, event.target.value);
  };

  const handleOnSearch = (event) => {
    const { id } = event.target;
    const { checked } = event.target;
    let category_ids = fquery;
    if (checked) {
      category_ids.push(id);
    } else {
      category_ids = category_ids.filter((items) => {
        return items != id;
      });
    }
    setFQuery(category_ids);
    filterFun(category_ids, prices);
  };

  const getPrice = (product_id) => {
    const index = _.findIndex(choseProducts, { product_id: product_id });
    if (index !== -1) {
      return choseProducts[index].price;
    } else {
      return 0;
    }
  };

  const handle_changeQty = (row, e) => {
    // setChoseProducts((prevItems) => {
    //   const index = _.findIndex(prevItems, { product_id: row?.id });
    //   if (index !== -1) {
    //     // If the item exists, update its value
    //     const updatedItems = [...prevItems];
    //     updatedItems[index] = {
    //       ...updatedItems[index],
    //       quantity: "1",
    //       price: row?.sale_price * 1,
    //       category_id: row?.catgory_id,
    //       reg_price: row?.reg_price,
    //       sale_price: row?.sale_price,
    //     };
    //     return updatedItems;
    //   } else {
    //     // If the item does not exist, push the new item
    //     return [
    //       ...prevItems,
    //       {
    //         product_id: row?.id,
    //         quantity: "1",
    //         price: row?.sale_price * 1,
    //         category_id: row?.catgory_id,
    //         reg_price: row?.reg_price,
    //         sale_price: row?.sale_price,
    //       },
    //     ];
    //   }
    // });
    // choseProducts.push({ product_id: row, quantity: e.target.value });
    // console.log(choseProducts)
    // setChoseProducts(choseProducts);
  };
  const calcTotal = () => {
    let qty = 0;
    let prds = 0;
    let totalamount = 0;

    choseProducts.forEach((product) => {
      console.log(product, "6789p");
      // let type = product.type;
      // let total_days = product.total_days;
      // let month_rate = product.month_rate;
      // let qty = product.qty;
      // if (type == "0") product.price = month_rate / total_days;
      if (typeof product.product_id !== "undefined") {
        console.log(parseFloat(product.quantity));
        if (product.quantity != "") {
          let productTotal = product.price;

          totalamount += parseFloat(productTotal);
          qty += parseFloat(product.quantity);
          if (product.quantity != "" && product.quantity != "0") {
            prds += 1;
          }
          console.log(total_amount, "tot");
          settotal_amount(totalamount);
          settotal_prd(prds);
          settotal_qty(qty);
        }
      }
    });
  };
  useEffect(() => {
    console.log(choseProducts, "choseProducts");
    if (choseProducts.length > 0) {
      calcTotal();
      // settotal_prd()
    }
  }, [choseProducts]);
  const filterFun = (fquery, prices) => {
    let filterArray = {};
    filterArray.category_ids = fquery;
    filterArray.prices = prices;
    dispatch(filterData({ filterArray }));
    setshowdata(1);
  };

  useEffect(() => {
    console.log(productIdDatas, "productIdDatas");
    if (productIdDatas) {
      let row = productIdDatas;
      if (row?.id) {
        setChoseProducts([]);
        setChoseProducts((prevItems) => {
          const index = _.findIndex(prevItems, { product_id: row?.id });
          if (index !== -1) {
            // If the item exists, update its value
            const updatedItems = [...prevItems];
            updatedItems[index] = {
              ...updatedItems[index],
              quantity: "1",
              price: row?.sale_price * 1,
              category_id: row?.catgory_id,
              reg_price: row?.reg_price,
              sale_price: row?.sale_price,
            };
            return updatedItems;
          } else {
            // If the item does not exist, push the new item
            return [
              ...prevItems,
              {
                product_id: row?.id,
                quantity: "1",
                price: row?.sale_price * 1,
                category_id: row?.catgory_id,
                reg_price: row?.reg_price,
                sale_price: row?.sale_price,
              },
            ];
          }
        });
      }
    }
  }, [productIdDatas]);
  const addcartProducts = (row) => {
    if (localStorage.getItem("isAuthenticated")) {
      const data = [];
      let addData = {};
      addData.product_id = row.id;
      addData.quantity = "1";
      addData.category_id = row.catgory_id;
      addData.reg_price = row.reg_price;
      addData.sale_price = row.sale_price;
      data.push(addData);
      dispatch(addcartProduct({ payload: data }));
    } else {
      navigate("/signin");
      toast.warning("Signin to continue...");
    }
  };
  useEffect(() => {
    dispatch(productdataDetails({ id }));
    // let getData = {};
    // getData.category_id = category_id;
    // getData.id = id;

    // dispatch(relatedProducts({ getData }));
  }, [id]);
  useEffect(() => {
    console.log(categoryDatas, "categoryDatas");
  }, [categoryDatas]);
  useEffect(() => {
    if (myOrderLoader === API_STATUS.FULFILLED) {
      setOrderplace(false);
      toast.dismiss();
      toast.success("Order Placed Successfully!");

      navigate("/thankyou");
      dispatch(clearMessages());
      dispatch(clearLoading());
      
    }
    if (myOrderLoader === API_STATUS.PENDING) {
      setOrderplace(true);
      toast.dismiss();
      toast.success("Please wait your order is being placed!");
    }
    if (errorMessage?.message) toast.error(errorMessage?.message);
    else if (errorMessage?.errors) {
      let data = "";
      console.log(errorMessage, "errorMessage");
      errorMessage?.errors.map((err) => {
        data += err.message + " ";
      });
      toast.error(data);
      // dispatch(clearLoadings());
    }
  }, [myOrderLoader, errorMessage, orderData]);

  return (
    <>
      <Header />

      {/* <!-- HERO SECTION PART START --> */}

      <div class="hero_section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="herosection_content">
                <h2>Our Price List</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- SECTION START  --> */}

      <section class="product_listings">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 d-none">
              <div class="sidebar">
                <div class="sidebar-section">
                  <div class="sidebar-wrapper">
                    <h5 class="wrapper-heading">Product Categories</h5>
                    <div class="sidebar-item">
                      <ul class="sidebar-list">
                        {categoryDatas &&
                          categoryDatas.map((row, index) => {
                            return (
                              <li>
                                <input
                                  type="checkbox"
                                  id={row?.id}
                                  name="category[]"
                                  onClick={handleOnSearch}
                                />
                                <label for={row?.id}>{row?.name}</label>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>

                  <div class="sidebar-wrapper sidebar-range">
                    <h5 class="wrapper-heading">Price Range</h5>
                    <div class="price-slide range-slider">
                      <div class="price">
                        <div class="range-wrap">
                          <div
                            class="range-value range-valueX"
                            id="rangeV"
                            ref={rangeVRef}
                          >
                            <span>{prices}</span>
                          </div>
                          <input
                            type="range"
                            ref={rangeRef}
                            min={settingDatas?.min_range ?? 200}
                            max={settingDatas?.max_range ?? 800}
                            defaultValue={800}
                            onChange={handleChange}
                            step="1"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 d-flex justify-content-end pb-3">
              <button
                className="btn border-radius-0 "
                onClick={() => {
                  window.print();
                }}
              >
                Download Price List
              </button>
            </div>
            <div class="col-lg-12">
              {/* <h3 class="section-title">Our Price List</h3> */}
              <div className="row">
                <div class="col-xs-12 col-sm-12 col-md-12 table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>IMAGES</th>
                        <th>PRODUCT</th>
                        <th>QUANTITY</th>
                        <th>PRICE</th>
                        {/* <th>SALE PRICE</th> */}
                        <th>UNIT</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      <>
                        <tr class="cartoverview_title">
                          <th colSpan={7} style={{ textAlign: "center" }}>
                            {productIdDatas?.name}
                          </th>
                        </tr>
                        <tr>
                          <td scope="row">
                            <div class="thamnail_img">
                              <img
                                src={API_BASE + productIdDatas?.image}
                                alt=""
                                height="50px"
                                width="50px"
                              />
                            </div>
                          </td>
                          <td class="align-middle">
                            <b>{productIdDatas?.name}</b>
                          </td>
                          <td class="align-middle">
                            <div class="cart d-flex">
                              <div class="number-input">
                                <input
                                  class="quantity"
                                  min="0"
                                  maxLength={2}
                                  name="quantity"
                                  value={1}
                                  readOnly
                                  onChange={(e) => {
                                    handle_changeQty(productIdDatas, e);
                                  }}
                                  type="number"
                                />
                              </div>
                            </div>
                          </td>
                          {/* <td class="align-middle">
                                      {productIdDatas?.reg_price}
                                    </td> */}
                          <td class="align-middle">
                            ₹{" "}
                            <s>
                              {parseFloat(productIdDatas?.reg_price).toFixed(2)}
                            </s>{" "}
                            <br />
                            <p className="pl-3">
                              {parseFloat(productIdDatas?.sale_price).toFixed(
                                2
                              )}
                            </p>
                          </td>
                          <td class="align-middle">
                            {productIdDatas?.unit_type == 1
                              ? "PKT"
                              : productIdDatas?.unit_type == 2
                              ? "PEC"
                              : productIdDatas?.unit_type == 3
                              ? "BAG"
                              : "BOX"}
                          </td>
                          <td class="align-middle">
                            ₹{getPrice(productIdDatas?.id)}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="container">
                <div class="row justify-content-between">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 pad-0">
                    <div class="profile_form">
                      <form onSubmit={handleSubmit(submitHandler)}>
                        <div class="form_group d-flex">
                          <div class="col-md-12 form_field">
                            <label for="first_name">Name</label>
                            <input
                              type="text"
                              name="first_name"
                              className="form-control"
                              {...register("first_name")}
                            />
                            <div className="mt-2 p-1">
                              {errors?.first_name?.message && (
                                <p className="error">
                                  {errors.first_name.message}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="form_group d-flex">
                          <div class="col-md-6 form_field">
                            <label for="mobile">Phone</label>
                            <input
                              type="text"
                              name="mobile"
                              className="form-control"
                              {...register("mobile")}
                            />
                            <div className="mt-2 p-1">
                              {errors?.mobile?.message && (
                                <p className="error">{errors.mobile.message}</p>
                              )}
                            </div>
                          </div>
                          <div class="col-md-6 form_field">
                            <label for="email">Email</label>
                            <input
                              type="text"
                              name="email"
                              className="form-control"
                              {...register("email")}
                            />
                            <div className="mt-2 p-1">
                              {errors?.email?.message && (
                                <p className="error">{errors.email.message}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="form_group d-flex">
                          <div class="col-md-6 form_field">
                            <label for="city">City</label>
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              value="Coimbatore"
                              readOnly
                              {...register("city")}
                            />
                            <div className="mt-2 p-1">
                              {errors?.city?.message && (
                                <p className="error">{errors.city.message}</p>
                              )}
                            </div>
                          </div>
                          <div class="col-md-6 form_field">
                            <label for="area">Area</label>
                            <select
                              name="area"
                              className="form-control"
                              {...register("area")}
                            >
                              <option value="">Select an area</option>
                              <option value="Signallur">Singanallur</option>
                              <option value="Ramanathapuram">
                                Ramanathapuram
                              </option>
                              <option value="Kovaipudur">Kovaipudur</option>
                              <option value="Selvapuram">Selvapuram</option>
                            </select>
                            <div className="mt-2 p-1">
                              {errors?.area?.message && (
                                <p className="error">{errors.area.message}</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6 form_field">
                          <label for="zipcode">PIN Code</label>
                          <input
                            type="text"
                            name="zipcode"
                            className="form-control"
                            {...register("zipcode")}
                          />
                          <div className="mt-2 p-1">
                            {errors?.zipcode?.message && (
                              <p className="error">{errors.zipcode.message}</p>
                            )}
                          </div>
                        </div>
                        <div class="form_group col-full d-flex">
                          <div class="col-md-12">
                            <div class="form_field">
                              <label for="address">Address</label>
                              <textarea
                                style={{ color: "black" }}
                                className="form-control"
                                name="address"
                                {...register("address")}
                              ></textarea>
                              <div className="mt-2 p-1">
                                {errors?.address?.message && (
                                  <p className="error">
                                    {errors.address.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="form_group flex justify-flex-start">
                          <div class="chechout_btn text-right">
                            <input
                              type="submit"
                              value="Submit"
                              name="submit"
                              disabled={orderplace}
                              class="btn border-radius-0 "
                              style={{
                                background:
                                  "linear-gradient(to right, #0f7404, #5cb917)",
                              }}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                    <div class="order_cardTotal">
                      <table class="table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              class="card_total text-uppercase"
                              colspan="2"
                            >
                              Cart total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row" class="subtotal">
                              Total quantity:
                            </th>
                            <td class="text-right">{total_qty}</td>
                          </tr>
                          <tr>
                            <th scope="row" class="discount">
                              Total Products:
                            </th>
                            <td class="text-right">{total_prd}</td>
                          </tr>
                          <tr>
                            <th scope="row">Total Amount:</th>
                            <td class="text-right total_num">
                              ₹{total_amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECTION END --> */}
      <Footer />
    </>
  );
};

export default BundlePage;
