import React, { useRef, useState, useEffect, useCallback } from "react";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { myOrderSchema } from "../../utils/Validation";
import dayjs from "dayjs";
import {
  productSelector,
  categoryList,
  productList,
  filterData,
} from "../../store/reducer/ProductReducer";
import {
  CartSelector,
  cartProductList,
  removecartProduct,
  addcartProduct,
  clearLoadings,
  getOrderDataByID,
} from "../../store/reducer/CartReducer";
import {
  MyOrderDetails,
  settingData,
  homeSelector,
  clearMessages,
} from "../../store/reducer/HomeReducer";
import debounce from "lodash/debounce";
import _ from "lodash";
import { API_BASE } from "../../services/config";
import { toast } from "react-toastify";
import { API_STATUS } from "../../utils/Constant";
import { orderDataById } from "../../services/api";
// import { homeSelector, settingData } from "../../store/reducer/HomeReducer";

const Thankyou = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [range, setRange] = useState(3);
  const [showdata, setshowdata] = useState(0);
  const [tax_amount, settax_amount] = useState(0);
  const [total_amount, settotal_amount] = useState(0);
  const [total_qty, settotal_qty] = useState(0);
  const [total_prd, settotal_prd] = useState(0);
  const [fquery, setFQuery] = useState([]);
  const userData = [];
  let [choseProducts, setChoseProducts] = useState([]);
  const [prices, setPrices] = useState("800");
  const [orderplace, setOrderplace] = useState(false);
  const [quantity_count, setQuantity] = useState(1);
  const rangeRef = useRef(0);
  const rangeVRef = useRef(0);
  const { settingDatas, myOrderLoader, orderData } = useSelector(homeSelector);
  const {dataOrderById}=useSelector(CartSelector)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    // resolver: yupResolver(myOrderSchema),
  });
  console.log(dataOrderById, 'dataOrderById')
  const submitHandler = (formData) => {
    console.log(formData, "Formdata MyAccount accounDetails");
    const ignoreDatas = [
      "created_at",
      "name",
      "image",
      "created_by",
      "updated_at",
      "updated_by",
    ];
    const ignorekeys = Object.keys(formData);
    for (let index = 0; index < ignorekeys.length; index++) {
      const element = ignorekeys[index];
      if (ignoreDatas.includes(element)) {
        console.log(element);
        delete formData[element];
      }
    }
    formData.products = choseProducts;
    formData.total_price = total_amount;
    console.log(formData, "formData");

    // let date = formData.dob;
    // formData.dob = dayjs(date).format("YYYY-MM-DD");
    dispatch(MyOrderDetails({ formData }));
  };

  const decreseQty = () => {
    let QtyCount = quantity_count - 1;
    QtyCount > 0 ? setQuantity(QtyCount) : setQuantity(1);
    console.log(QtyCount, "TOASTS");
  };

  const increseQty = () => {
    let QtyCount = quantity_count + 1;
    setQuantity(QtyCount);
    console.log(QtyCount, "TOASTS");
  };

  useEffect(() => {
    setPrices(settingDatas?.max_range ?? "800");
  }, [settingDatas]);

  const {
    categoryDatas,
    productDatas,
    productFilterDatas,
    productLoading,
    catgLoading,
  } = useSelector(productSelector);
  const {
    cartProductDatas,
    cartProducttotal,
    cartLoading,
    removecartLoading,
    addcartLoading,
    errorMessage,
  } = useSelector(CartSelector);
  useEffect(() => {
    let page = 0;
    let limit = 0;
    let query = "";

    dispatch(categoryList({ page, limit, query }));
    dispatch(productList({ page, limit, query }));
    dispatch(settingData({}));
  }, []);

  useEffect(() => {
    console.log(categoryDatas, "categoryDatas");
  }, [categoryDatas]);

  useEffect(() => {
    console.log(productDatas, "productDatas");
  }, [productDatas]);
  useEffect(() => {
    console.log(productFilterDatas, "productFilterDatas");
  }, [productFilterDatas]);

  const handleChange = (event) => {
    setPrices(event.target.value);
    const newValue = Number(
        ((event.target.value - rangeRef.current.min) * 100) /
          (rangeRef.current.max - rangeRef.current.min)
      ),
      newPosition = 10 - newValue * 0.2;
    rangeVRef.current.classList.remove("range-valueX");
    rangeVRef.current.innerHTML = `<span>${event.target.value}</span>`;
    rangeVRef.current.style.left = `calc(${newValue}% + (${newPosition}px))`;
    console.log(event.target.value, "ONPRICES");
    filterFun(fquery, event.target.value);
  };

  const handleOnSearch = (event) => {
    const { id } = event.target;
    const { checked } = event.target;
    let category_ids = fquery;
    if (checked) {
      category_ids.push(id);
    } else {
      category_ids = category_ids.filter((items) => {
        return items != id;
      });
    }
    setFQuery(category_ids);
    filterFun(category_ids, prices);
  };

  const getPrice = (product_id) => {
    const index = _.findIndex(choseProducts, { product_id: product_id });
    if (index !== -1) {
      return choseProducts[index].price;
    } else {
      return 0;
    }
  };

  const handle_changeQty = (row, e) => {
    setChoseProducts((prevItems) => {
      const index = _.findIndex(prevItems, { product_id: row?.id });

      if (index !== -1) {
        // If the item exists, update its value
        const updatedItems = [...prevItems];
        updatedItems[index] = {
          ...updatedItems[index],
          quantity: e.target.value,
          price: row?.sale_price * e.target.value,
          category_id: row?.catgory_id,
          reg_price: row?.reg_price,
          sale_price: row?.sale_price,
        };
        return updatedItems;
      } else {
        // If the item does not exist, push the new item
        return [
          ...prevItems,
          {
            product_id: row?.id,
            quantity: e.target.value,
            price: row?.sale_price * e.target.value,
            category_id: row?.catgory_id,
            reg_price: row?.reg_price,
            sale_price: row?.sale_price,
          },
        ];
      }
    });
    // choseProducts.push({ product_id: row, quantity: e.target.value });
    // console.log(choseProducts)
    // setChoseProducts(choseProducts);
  };
  const calcTotal = () => {
    let qty = 0;
    let prds = 0;
    let totalamount = 0;

    dataOrderById[0]?.orderdetails?.forEach((product) => {
      console.log(product, "6789p");  
      console.log(parseFloat(product.quantity));
      if (product.quantity != 0 && product.quantity != "") {
        let productTotal = product.price;

        totalamount += parseFloat(productTotal);
        qty += parseFloat(product.quantity);
        prds += 1;
        console.log(total_amount, "tot");
        settotal_amount(totalamount);
        settotal_prd(prds);
        settotal_qty(qty);
      }
    });
  };
  useEffect(() => {
    console.log(dataOrderById, "choseProducts");
    if (dataOrderById) {
      calcTotal();
      // settotal_prd()
    }
  }, [dataOrderById]);
  const filterFun = (fquery, prices) => {
    let filterArray = {};
    filterArray.category_ids = fquery;
    filterArray.prices = prices;
    dispatch(filterData({ filterArray }));
    setshowdata(1);
  };

  const addcartProducts = (row) => {
    if (localStorage.getItem("isAuthenticated")) {
      const data = [];
      let addData = {};
      addData.product_id = row.id;
      addData.quantity = "1";
      addData.category_id = row.catgory_id;
      addData.reg_price = row.reg_price;
      addData.sale_price = row.sale_price;
      data.push(addData);
      dispatch(addcartProduct({ payload: data }));
    } else {
      navigate("/signin");
      toast.warning("Signin to continue...");
    }
  };
  useEffect(() => {
    console.log(categoryDatas, "categoryDatas");
    let id=orderData?.id
    dispatch(getOrderDataByID({id}))
  }, [categoryDatas]);
  useEffect(() => {
    if (myOrderLoader === API_STATUS.FULFILLED) {
      setOrderplace(false);
      toast.dismiss();
      toast.success("Order Placed Successfully!");
      // navigate("/");
   
    }
    if (myOrderLoader === API_STATUS.PENDING) {
      setOrderplace(true);
      toast.dismiss();
      toast.success("Please wait your order is being placed!");
      // navigate("/");
    }
    if (errorMessage?.message) toast.error(errorMessage?.message);
    else if (errorMessage?.errors) {
      let data = "";
      console.log(errorMessage, "errorMessage");
      errorMessage?.errors.map((err) => {
        data += err.message + " ";
      });
      toast.error(data);
      // dispatch(clearLoadings());
    }
  }, [myOrderLoader, errorMessage]);

  return (
    <>
      <Header />

      {/* <!-- HERO SECTION PART START --> */}

      <div class="hero_section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
              <div class="herosection_content">
                <h2>Order Confirmation</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- SECTION START  --> */}
      <div className="col-lg-12 d-flex justify-content-end ">
              <button className="btn border-radius-0 " onClick={()=>{
                window.print()
              }}>Download Order List</button>
            </div>
      <section class="pad-80 product_listings">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <h3 class="section-title">ORDER No:{(orderData?.order_no)} </h3>
              <p style={{color:"green",fontSize:"20px"}}>Your order is successfull!<br/> Our team will contact you for the payment details soon.</p>
              <div className="row mt-1">
                <div class="col-xs-12 col-sm-12 col-md-12 table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>IMAGES</th>
                        <th>PRODUCT</th>
                        <th>ACTUAL PRICE</th>
                        <th>SALE PRICE</th>
                        <th>UNIT</th>
                        <th>QUANTITY</th>
                        <th>TOTAL</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataOrderById &&
                        dataOrderById.map((row, index) => {
                          return (
                            <>
                              <tr class="cartoverview_title">
                                <th colSpan={7} style={{ textAlign: "center" }}>
                                  {row?.name}
                                </th>
                              </tr>
                              {row?.orderdetails.map((row1) => {
                                return (
                                  <tr>
                                    <td scope="row">
                                      <div class="thamnail_img">
                                        <img
                                          src={API_BASE + row1?.products?.image}
                                          alt=""
                                          height="50px"
                                          width="50px"
                                        />
                                      </div>
                                    </td>
                                    <td class="align-middle">
                                      <b>{row1?.products?.name}</b>
                                    </td>
                                    <td class="align-middle">
                                      {row1?.reg_price}
                                    </td>
                                    <td class="align-middle">
                                      {row1?.sale_price}
                                    </td>
                                    <td class="align-middle">
                                      {row1?.unit_type == 1
                                        ? "PKT"
                                        : row1?.unit_type == 2
                                        ? "PEC"
                                        : row1?.unit_type == 3
                                        ? "BAG"
                                        : "BOX"}
                                    </td>
                                    <td class="align-middle">
                                      {/* <div class="cart d-flex">
                                        <div class="number-input">
                                          <input
                                            class="quantity"
                                            min="0"
                                            name="quantity"
                                            value={row1?.quantity}
                                            onChange={(e) => {
                                              handle_changeQty(row1, e);
                                            }}
                                            type="number"
                                          />
                                        </div>
                                      </div> */}
                                      {row1?.quantity}
                                    </td>
                                    <td class="align-middle">
                                      ₹{parseInt(row1?.sale_price* parseInt(row1?.quantity))}
                                    </td>
                                  </tr>
                                );
                              })}
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="container">
                <div class="row justify-content-end">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                    <div class="order_cardTotal">
                      <table class="table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              class="card_total text-uppercase"
                              colspan="2"
                            >
                              Cart total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th scope="row" class="subtotal">
                              Total quantity:
                            </th>
                            <td class="text-right">{total_qty}</td>
                          </tr>
                          <tr>
                            <th scope="row" class="discount">
                              Total Products:
                            </th>
                            <td class="text-right">{total_prd}</td>
                          </tr>
                          <tr>
                            <th scope="row">Total Amount:</th>
                            <td class="text-right total_num">
                              ₹{total_amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- SECTION END --> */}
      <Footer />
    </>
  );
};

export default Thankyou;
